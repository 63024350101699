import { getHost, request } from 'src/utils';
import AuthService from './auth';

export default class Project {

  /**
   *
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.domain
   * @param {string} data.note
   * @param {('brand' | 'category')} data.searchAngle
   * @param {boolean} data.isTemplate
   */
  static createProject = async (data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        ...data,
        isExternal: false
      }
    };
    const res = await request(options);
    return res.data.result;
  };


  static deleteProject = async (id) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }


  /**
   *
   * @param {string} id required
   * @param {Object} data required
   * @param {string} data.name
   * @param {string} data.note
   * @param {string} data.userId
   * @param {string} data.teamId
   */
  static updateProject = async (id, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }


  /**
   * query all projects
   * @param {Object} data
   * @param {('startDateAsc' | 'startDateDesc' | 'endDateAsc' | 'endDateDesc')} data.order required
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string} data.domain
   * @param {boolean} data.isTemplate
   * @param {('brand' | 'category')} data.searchAngle
   * @param {('run' | 'notRun' | 'outOfDate')} data.status
   * @param {string} data.userId
   * @param {string} data.teamId
   */
  static queryProjects = async (data, isTemplate = true) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        isTemplate,
        ...data
      }
    };
    const res = await request(options);
    const { projects, anchor } = res.data;
    return { projects, anchor };
  };


  /**
   * get specific project by id
   */
  static getProject = async (id) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data.result;
  };


  /**
   * get voc range of project by id
   * 口碑及特性命中區間
   *
   * @param {string} id
   * @param {'keyword'|'feature'} type required
   */
  static getVocRange = async (id, type = 'keyword') => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/vocRange`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    const raw = res.data.result;
    const result = raw.filter((item) => item.type === type);
    return result;
  };


  /**
   * query my own projects
   * @param {Object} data
   * @param {('startDateAsc' | 'startDateDesc' | 'endDateAsc' | 'endDateDesc')} data.order required
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string} data.domain
   * @param {boolean} data.isTemplate
   * @param {('brand' | 'category')} data.searchAngle
   * @param {('run' | 'notRun' | 'outOfDate')} data.status
   */
  static queryMyProject = async (data) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/my`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    const { projects, anchor } = res.data;
    return { projects, anchor };
  };

  /**
   * query my own deleted projects
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string} data.domain
   * @param {boolean} data.isTemplate
   * @param {('brand' | 'category')} data.searchAngle
   * @param {('run' | 'notRun' | 'outOfDate')} data.status
   */
  static queryMyDeletedProject = async (data) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/my/deleted`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    const { projects, anchor } = res.data;
    return { projects, anchor };
  };

}

