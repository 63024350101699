import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ProjectService from 'src/services/project';
import FeatureSetService from 'src/services/featureSet';
import { LIMIT, ORDER } from 'src/constants';
import ModalViewModel from 'src/viewModels/modal';

export default class RefDrawerVM {
  @observable type = '';
  @observable list = [];
  @observable anchor = null;
  @observable isLoading = false;
  @observable isRunningRef = false;
  @observable searchConditions = {
    order: ORDER.createDateDesc
  };
  @observable featureFiles = [];
  @observable currentRefFid = '';
  @observable modalVM = new ModalViewModel();

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    this.getList();
  }

  @action
  setType = (type) => {
    switch (type) {
      case 'channel':
        this.type = '頻道組合';
        break;
      case 'search':
        this.type = '搜尋關鍵字';
        break;
      case 'feature':
        this.type = '特性關鍵字';
        break;
      default:
    }
  }

  @computed
  get currentFeature() {
    return this.featureFiles.find((f) => f.fid === this.currentRefFid);
  }


  @action
  onFeatureTabChange = (fid) => {
    if (this.type !== '特性關鍵字') return;
    this.currentRefFid = fid;
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });

      const { projects, anchor } = await ProjectService.queryProjects({
        ...this.searchConditions,
        limit: LIMIT,
        anchor: this.anchor
      }, true);

      runInAction(() => {
        this.list = [...this.list, ...projects];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      // TODO error message
    }
  }

  getFeatureFiles = async (projectId) => {
    const { features } = await FeatureSetService.queryFeatureSet(projectId);
    runInAction(() => {
      this.featureFiles = (features.length > 0) ? features : [{ fid: '', name: '尚無資料' }];
    });
  }

  @action
  setIsRunningRef = (boolean) => {
    this.isRunningRef = boolean;
  }

  @action
  clearFeatureFiles = () => {
    this.featureFiles = [];
  }

  @action
  clear = () => {
    this.anchor = null;
    this.list = [];
    this.featureFiles = [];
    // this.type = '';
  }

  @action
  refresh = () => {
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  @action
  onSearch = (conditions) => {
    this.searchConditions = conditions;
    this.refresh();
  }

  onBotton = () => {
    if (this.anchor) this.getList();
  }


}
