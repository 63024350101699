export const AUTH_TOKEN_KEY = 'token';
export const LIMIT = 40;
export const LARGE_LIMIT = 100000;

export const CUSTOMER_DEPARTMENT_ID = '200000000000000000000000';
export const NO_DEPARTMENT_ID = '000000000000000000000001';


export const MODE = {
  PUBLIC: 'public', // 公版
  PRIVATE: 'private', // 我的專案
  DRAWER: 'drawer', // 側邊欄
  EDITING: 'editing',
  DELETED: 'deleted' // 已刪除
};

export const ORDER = {
  startDateAsc: 'startDateAsc',
  startDateDesc: 'startDateDesc',
  endDateAsc: 'endDateAsc',
  endDateDesc: 'endDateDesc',
  createDateAsc: 'createDateAsc',
  createDateDesc: 'createDateDesc'
};

export const NAME_ORDER = {
  ASC: 'nameAsc',
  DESC: 'nameDesc'
};

export const PROJECT_STATUS = {
  NOT_RUN: 'notRun',
  RUN: 'run',
  OUT_OF_DATE: 'outOfDate'
};

export const SEARCH_KEYWORD_TXT = {
  brand: {
    L1: '品牌',
    L2: '產品線',
    L3: '產品',
    all: '產品線＋產品'
  },
  category: {
    L1: '品類',
    L2: '品牌',
    L3: '產品',
    all: '品牌＋產品'
  }
};

export const CHANNEL_SOURCE = {
  article: 'article',
  blog: 'blog',
  forum: 'forum',
  news: 'news',
  place: 'place',
  sns: 'sns',
  video: 'video',
  wiki: 'wiki'
};

export const USER_STATUS = {
  ENABLE: {
    value: 'enable',
    name: '可登入'
  },
  DISABLE: {
    value: 'disable',
    name: '不可登入'
  }
};

export const USER_STATUS_TXT = {
  enable: '可登入',
  disable: '不可登入'
};

export const USER_PERMISSION = {
  ADMIN: {
    value: 'admin',
    name: '管理者'
  },
  TEAM_LEADER: {
    value: 'teamLeader',
    name: '組長'
  },
  MEMBER: {
    value: 'member',
    name: '組員'
  }
};

export const USER_PERMISSION_TXT = {
  admin: '管理者',
  teamLeader: '組長',
  member: '組員',
  none: '無'
};

export const ACCOUNT_STATUS = {
  ACTIVATE: {
    value: 'activate',
    name: '正常'
  },
  DEACTIVATE: {
    value: 'deactivate',
    name: '停用'
  }
};
export const ACCOUNT_STATUS_TXT = {
  activate: '正常',
  deactivate: '停用'
};

export const USER_ORDER = {
  DEPARTMENT: 'department',
  ACCOUNT_STATUS: 'accountStatus'
};

export const DOWNLOAD_TOPIC_TYPE = {
  ALL: {
    value: 'all',
    name: '口碑發回文話題列表'
  },
  POSTS: {
    value: 'posts',
    name: '口碑發文話題列表'
  },
  BUILDINGS: {
    value: 'buildings',
    name: '命中大樓話題列表'
  },
  FEATURE_POSTS: {
    value: 'featurePosts',
    name: '特性話題列表'
  },
  FEATURE_ALL: {
    value: 'featureAll',
    name: '特性發回文話題列表'
  }
};

export const LOG_TYPE = {
  init: {
    value: 'init',
    name: '啟動專案'
  },
  backtrack: {
    value: 'backtrack',
    name: '手動回溯'
  },
  scheduled: {
    value: 'scheduled',
    name: '自動排程'
  },
  update: {
    value: 'update',
    name: '刪除/停用/清空關鍵字'
  }
};

export const CHANNEL_SORT = {
  DEFAULT: 'default',
  ASC: 'halfYearAsc',
  DESC: 'halfYearDesc'
};

export const LOG_STAGE = {
  WAITING: {
    value: 'WAITING',
    name: '等待分析中'
  },
  BEGIN: {
    value: 'BEGIN',
    name: '開始計算'
  },
  SEARCH: {
    value: 'SEARCH',
    name: '話題搜尋'
  },
  FEATURE: {
    value: 'FEATURE',
    name: '特性比對'
  },
  UPDATE: {
    value: 'UPDATE',
    name: '更新結果'
  },
  TREND: {
    value: 'TREND',
    name: '計算 Trend'
  },
  FAIL: {
    value: 'FAIL',
    name: '失敗'
  },
  DONE: {
    value: 'DONE',
    name: '完成'
  },
  CANCEL: {
    value: 'CANCEL',
    name: '已取消'
  }
};

export const PROJECT_DOMAINS = [
  '美妝保養',
  '婦幼嬰兒',
  '金融',
  '科技3C',
  '家電',
  '廚房家電',
  '政治議題',
  '食品飲品',
  '保健食品',
  '運動',
  '旅遊',
  '汽機車產業',
  '寵物',
  '地產建設',
  '電商',
  '語言學習',
  '電影戲劇',
  '藝文活動',
  '居家用品',
  '通路',
  '電信',
  '服飾鞋包',
  '精品',
  '其他'
];


export const MAINTENANCE_TIME = ['2024-08-17 00:00', '2024-08-18 23:59'];
