import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ProjectService from 'src/services/project';
import { LIMIT, ORDER } from 'src/constants';

export default class AllProjectPageVM {
  @observable list = [];
  @observable anchor = null;
  @observable isLoading = false;
  @observable searchConditions = {
    order: ORDER.createDateDesc
  };

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    this.getList();
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });

      const { projects, anchor } = await ProjectService.queryProjects({
        ...this.searchConditions,
        limit: LIMIT,
        anchor: this.anchor
      }, true);

      runInAction(() => {
        this.list = [...this.list, ...projects];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      // TODO error message
    }
  }

  @action
  refresh = () => {
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  @action
  onSearch = (conditions) => {
    this.searchConditions = conditions;
    this.refresh();
  }

  onBotton = () => {
    if (this.anchor) this.getList();
  }


}
