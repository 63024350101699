import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import UserService from 'src/services/user';
import ProjectService from 'src/services/project';
import EsService from 'src/services/es';
import { keywordParse } from 'src/services/keywordParser';
import { LARGE_LIMIT, ORDER } from 'src/constants';
import { errorModal } from 'src/utils';

export default class Es {
  @observable isLoading = false;
  @observable data = {
    keyword: '',
    startDate: null,
    endDate: null,
    ownerId: null,
    domain: null,
    selectProjectId: null,
    inputProjectId: null
  };
  @observable isExpand = true;
  @observable users = [];
  @observable projects = [];
  @observable list = [];
  @observable keywordResult = {
    result: null,
    reasons: [],
    hasError: false,
    hasWaran: false
  };

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    this.getUsers();
  }

  getUsers = async () => {
    try {
      const { users } = await UserService.getUsers({
        limit: LARGE_LIMIT,
        order: 'department'
      });
      runInAction(() => {
        this.users = users;
      });

    } catch {
      errorModal('發生錯誤，無法取得負責人資訊');
    }
  }

  getProjects = async () => {
    if (!this.data.ownerId) return;

    try {
      const { projects } = await ProjectService.queryProjects({
        order: ORDER.createDateDesc,
        domain: this.data.domain,
        userId: this.data.ownerId
      }, false);

      runInAction(() => {
        this.projects = projects;
      });

    } catch {
      errorModal('發生錯誤，無法取得專案資訊');
    }

  }

  @action
  toggleExpand = () => {
    this.isExpand = !this.isExpand;
  }

  @computed
  get searchable() {
    return !this.keywordResult.hasError
      && this.data.keyword
      && this.data.startDate
      && this.data.endDate;
  }

  onSearch = async () => {
    try {
      runInAction(() => {
        this.list = [];
        this.isLoading = true;
      });

      const queryData = {
        keyword: this.data.keyword.trim(),
        startDate: this.data.startDate.startOf('day')?.toISOString(),
        endDate: this.data.endDate.endOf('day')?.toISOString(),
        projectId: this.data.selectProjectId || this.data.inputProjectId || undefined
      };

      const res = await EsService.get(queryData);

      runInAction(() => {
        this.list = res;
        this.isLoading = false;
      });

    } catch {
      runInAction(() => { this.isLoading = false; });
      errorModal('發生錯誤，無法取得資料');
    }
    return null;
  }

  @action
  reset = () => {
    this.data = {
      keyword: '',
      startDate: null,
      endDate: null,
      ownerId: null,
      domain: null,
      selectProjectId: null,
      inputProjectId: null
    };
  }

  @action
  onKeywordChange = (e) => {
    this.data.keyword = e.target.value;

    const { result, reasons } = keywordParse(this.data.keyword);
    runInAction(() => {
      this.keywordResult = {
        result,
        reasons,
        hasError: (result === 'error') || (result === 'notComplete'),
        hasWaran: result === 'warn'
      };
    });
  }

  @action
  onDateChange = (date) => {
    if (!date) {
      this.data.startDate = null;
      this.data.endDate = null;
    } else {
      this.data.startDate = date[0];
      this.data.endDate = date[1];
    }
  }

  @action
  onOwnerChange = (ownerId) => {
    this.data.ownerId = ownerId;
    this.data.inputProjectId = null;
    this.getProjects();
  }

  @action
  onDomainChange = (domain) => {
    this.data.domain = domain;
    this.data.inputProjectId = null;
    this.getProjects();
  }

  @action
  onSelectProjectIdChange = (projectId) => {
    this.data.selectProjectId = projectId;
    this.data.inputProjectId = null;
  }

  @action
  onInputProjectIdChange = (e) => {
    this.data.inputProjectId = e.target.value;
    this.data.selectProjectId = null;
    this.data.domain = null;
    this.data.ownerId = null;
  }
}
